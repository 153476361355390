import { AwsEc2Details } from './aws-prices-details';
import {
  filterArmAmis,
  onlyShowAllowList,
} from '../../routes/member/aws/compute/ec2/slices/createEc2Helpers';

const Ec2Details: AwsEc2Details[] = [
  {
    type: 'a1.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'a1.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'a1.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'a1.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'a1.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'a1.metal',
    class: 'General purpose',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton Processor',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c1.medium',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '1.7 GiB',
    storage: '1 x 350 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c1.xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '7 GiB',
    storage: '4 x 420 SSD',
    io: 'High',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c3.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '3.75 GiB',
    storage: '2 x 16 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2680 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c3.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '7.5 GiB',
    storage: '2 x 40 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2680 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c3.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '15 GiB',
    storage: '2 x 80 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2680 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c3.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '30 GiB',
    storage: '2 x 160 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2680 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c3.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '60 GiB',
    storage: '2 x 320 SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2680 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'c4.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '3.75 GiB',
    storage: 'EBS only',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2666 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c4.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '7.5 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2666 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c4.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '15 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2666 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c4.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '30 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2666 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c4.8xlarge',
    class: 'Compute optimized',
    vCPUs: '36',
    ram: '60 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2666 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c5.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.9xlarge',
    class: 'Compute optimized',
    vCPUs: '36',
    ram: '72 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.18xlarge',
    class: 'Compute optimized',
    vCPUs: '72',
    ram: '144 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5.metal',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5a.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: '2 x 600 NVMe SSD',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1200 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5ad.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: '1 x 50 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: '1 x 100 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: '1 x 200 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: '1 x 400 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.9xlarge',
    class: 'Compute optimized',
    vCPUs: '36',
    ram: '72 GiB',
    storage: '1 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '12 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.18xlarge',
    class: 'Compute optimized',
    vCPUs: '72',
    ram: '144 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5d.metal',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '5.25 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '10.5 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '21 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '42 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.9xlarge',
    class: 'Compute optimized',
    vCPUs: '36',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.18xlarge',
    class: 'Compute optimized',
    vCPUs: '72',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c5n.metal',
    class: 'Compute optimized',
    vCPUs: '72',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8124M',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.32xlarge',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.48xlarge',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6a.metal',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6g.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6g.metal',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gd.metal',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '75 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6gn.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6i.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.32xlarge',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6i.metal',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c6id.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: '1 x 474 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: '4 x 1425 NVMe SSD',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.32xlarge',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6id.metal',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 30000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 40000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '100000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '150000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.32xlarge',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c6in.metal',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7a.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.32xlarge',
    class: 'Compute optimized',
    vCPUs: '128',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.48xlarge',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7a.metal-48xl',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7g.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7g.metal',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gd.metal',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.medium',
    class: 'Compute optimized',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 40 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 50 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '150 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7gn.metal',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'c7i.large',
    class: 'Compute optimized',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.xlarge',
    class: 'Compute optimized',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.2xlarge',
    class: 'Compute optimized',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.12xlarge',
    class: 'Compute optimized',
    vCPUs: '48',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.24xlarge',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.metal-24xl',
    class: 'Compute optimized',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.48xlarge',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'c7i.metal-48xl',
    class: 'Compute optimized',
    vCPUs: '192',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'cr1.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '244 GiB',
    storage: '2 x 120 SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2670',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'd2.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: '3 x 2000 HDD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'd2.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '61 GiB',
    storage: '6 x 2000 HDD',
    io: 'High',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'd2.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '122 GiB',
    storage: '12 x 2000 HDD',
    io: 'High',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'd2.8xlarge',
    class: 'Storage optimized',
    vCPUs: '36',
    ram: '244 GiB',
    storage: '24 x 2000 HDD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'd3.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '3 x 2000 HDD',
    io: 'Up to 15 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '6 x 2000 HDD',
    io: 'Up to 15 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '12 x 2000 HDD',
    io: 'Up to 15 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '24 x 2000 HDD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '2 x 14000 HDD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '4 x 14000 HDD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '8 x 14000 HDD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.6xlarge',
    class: 'Storage optimized',
    vCPUs: '24',
    ram: '96 GiB',
    storage: '12 x 14000 HDD',
    io: '40 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '16 x 14000 HDD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'd3en.12xlarge',
    class: 'Storage optimized',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '24 x 14000 HDD',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'dl1.24xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 1000 GB NVMe SSD',
    io: '400 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'f1.2xlarge',
    class: 'FPGA Instances',
    vCPUs: '8',
    ram: '122 GiB',
    storage: '1 x 470 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'f1.4xlarge',
    class: 'FPGA Instances',
    vCPUs: '16',
    ram: '244 GiB',
    storage: '1 x 940 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'f1.16xlarge',
    class: 'FPGA Instances',
    vCPUs: '64',
    ram: '976 GiB',
    storage: '4 x 940 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'g2.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '15 GiB',
    storage: '1 x 60 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 (Sandy Bridge)',
    currentGeneration: 'No',
    gpu: '1',
    gpuMemory: '4 GiB',
    allowList: true,
  },
  {
    type: 'g2.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '60 GiB',
    storage: '2 x 120 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 (Sandy Bridge)',
    currentGeneration: 'No',
    gpu: '4',
    gpuMemory: '16 GiB',
    allowList: true,
  },
  {
    type: 'g3.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '122 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '8 GB',
    allowList: true,
  },
  {
    type: 'g3.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '244 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '2',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g3.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '488 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '32 GB',
    allowList: true,
  },
  {
    type: 'g3s.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '8 GB',
    allowList: true,
  },
  {
    type: 'g4ad.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '150 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '8 GB',
    allowList: false,
  },
  {
    type: 'g4ad.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '300 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '8 GB',
    allowList: false,
  },
  {
    type: 'g4ad.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '600 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '8 GB',
    allowList: false,
  },
  {
    type: 'g4ad.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1200 GB NVMe SSD',
    io: '15 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '2',
    gpuMemory: '16 GB',
    allowList: false,
  },
  {
    type: 'g4ad.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2400 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'g4dn.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '125 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g4dn.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '225 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g4dn.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '225 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g4dn.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '900 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g4dn.12xlarge',
    class: 'GPU instance',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '900 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '64 GB',
    allowList: true,
  },
  {
    type: 'g4dn.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '900 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: true,
  },
  {
    type: 'g4dn.metal',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '2 x 900 GB NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '128 GB',
    allowList: true,
  },
  {
    type: 'g5.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 250 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g5.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 450 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g5.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 600 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g5.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1 x 900 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g5.12xlarge',
    class: 'GPU instance',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '1 x 3800 GB NVMe SSD',
    io: '40 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '96 GB',
    allowList: true,
  },
  {
    type: 'g5.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '1 x 1900 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g5.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '1 x 3800 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '96 GB',
    allowList: true,
  },
  {
    type: 'g5.48xlarge',
    class: 'GPU instance',
    vCPUs: '192',
    ram: '768 GiB',
    storage: '2 x 3800 GB NVMe SSD',
    io: '100 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '192 GB',
    allowList: true,
  },
  {
    type: 'g5g.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: false,
  },
  {
    type: 'g5g.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: false,
  },
  {
    type: 'g5g.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: false,
  },
  {
    type: 'g5g.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GB',
    allowList: false,
  },
  {
    type: 'g5g.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '2',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'g5g.metal',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    gpu: '2',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'g6.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 250 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g6.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 450 GB NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g6.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 600 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g6.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '2 x 450 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g6.12xlarge',
    class: 'GPU instance',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '4 X 940 GB NVMe SSD',
    io: '40 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '96 GB',
    allowList: true,
  },
  {
    type: 'g6.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 940 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'g6.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 X 940 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '96 GB',
    allowList: true,
  },
  {
    type: 'g6.48xlarge',
    class: 'GPU instance',
    vCPUs: '192',
    ram: '768 GiB',
    storage: '8 x 940 NVMe SSD',
    io: '100 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '192 GB',
    allowList: true,
  },
  {
    type: 'g6e.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 250 GB NVMe SSD',
    io: 'Up to 20 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '48 GB',
    allowList: true,
  },
  {
    type: 'g6e.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 450 GB NVMe SSD',
    io: 'Up to 20 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '48 GB',
    allowList: true,
  },
  {
    type: 'g6e.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 600 GB NVMe SSD',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '48 GB',
    allowList: true,
  },
  {
    type: 'g6e.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '1 x 450 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '48 GB',
    allowList: true,
  },
  {
    type: 'g6e.12xlarge',
    class: 'GPU instance',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '192 GB',
    allowList: true,
  },
  {
    type: 'g6e.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '1 x 900 NVMe SSD',
    io: '35 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '48 GB',
    allowList: true,
  },
  {
    type: 'g6e.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '200 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '192 GB',
    allowList: true,
  },
  {
    type: 'g6e.48xlarge',
    class: 'GPU instance',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '400 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '384 GB',
    allowList: true,
  },
  {
    type: 'gr6.4xlarge',
    class: 'GPU instance',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 600 GB NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'gr6.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 450 GB NVMe SSD',
    io: '25 Gigabit',
    processor: 'AMD EPYC 7R32',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '24 GB',
    allowList: true,
  },
  {
    type: 'h1.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 2000 HDD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'h1.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '2 x 2000 HDD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'h1.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '4 x 2000 HDD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'h1.16xlarge',
    class: 'Storage optimized',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '8 x 2000 HDD',
    io: '25 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'hpc7g.16xlarge',
    class: 'Compute optimized',
    vCPUs: '64',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'hpc7g.4xlarge',
    class: 'Compute optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'hpc7g.8xlarge',
    class: 'Compute optimized',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i2.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: '1 x 800 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'i2.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '61 GiB',
    storage: '2 x 800 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'i2.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '122 GiB',
    storage: '4 x 800 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'i2.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '244 GiB',
    storage: '8 x 800 SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'i3.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '15.25 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '61 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '122 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '244 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.16xlarge',
    class: 'Storage optimized',
    vCPUs: '64',
    ram: '488 GiB',
    storage: '8 x 1900 NVMe SSD',
    io: '20 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3.metal',
    class: 'Storage optimized',
    vCPUs: '72',
    ram: '512 GiB',
    storage: '8 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 1250 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 2500 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '2 x 2500 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.3xlarge',
    class: 'Storage optimized',
    vCPUs: '12',
    ram: '96 GiB',
    storage: '1 x 7500 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.6xlarge',
    class: 'Storage optimized',
    vCPUs: '24',
    ram: '192 GiB',
    storage: '2 x 7500 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.12xlarge',
    class: 'Storage optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '4 x 7500 NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.24xlarge',
    class: 'Storage optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '8 x 7500 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i3en.metal',
    class: 'Storage optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '8 x 7500 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4g.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 468 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4g.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 937 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4g.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 1875 SSD',
    io: 'Up to 12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4g.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 3750 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4g.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 3750 SSD',
    io: '18750 Megabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4g.16xlarge',
    class: 'Storage optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '4 x 3750 SSD',
    io: '37500 Megabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'i4i.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 468 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 937 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 1875 NVMe SSD',
    io: 'Up to 12 Gigabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 3750 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 3750 NVMe SSD',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.12xlarge',
    class: 'Storage optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '3 x 3750 NVMe SSD',
    io: '28125 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.16xlarge',
    class: 'Storage optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '4 x 3750 NVMe SSD',
    io: '35000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.24xlarge',
    class: 'Storage optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '6 x 3750 NVMe SSD',
    io: '56250 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.32xlarge',
    class: 'Storage optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '8 x 3750 SSD',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'i4i.metal',
    class: 'Storage optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '8 x 3750 NVMe SSD',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'im4gn.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 937 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'im4gn.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 1875 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'im4gn.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 3750 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'im4gn.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 7500 SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'im4gn.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '2 x 7500 SSD',
    io: '50 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'im4gn.16xlarge',
    class: 'Storage optimized',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '4 x 7500 SSD',
    io: '100 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'inf1.xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '4',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8275CL (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'inf1.2xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '8',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8275CL (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'inf1.6xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '24',
    ram: '48 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8275CL (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'inf1.24xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8275CL (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'inf2.xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'inf2.8xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'inf2.24xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpuMemory: '192 GB',
    allowList: false,
  },
  {
    type: 'inf2.48xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpuMemory: '384 GB',
    allowList: false,
  },
  {
    type: 'is4gen.medium',
    class: 'Storage optimized',
    vCPUs: '1',
    ram: '6 GiB',
    storage: '1 x 937 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'is4gen.large',
    class: 'Storage optimized',
    vCPUs: '2',
    ram: '12 GiB',
    storage: '1 x 1875 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'is4gen.xlarge',
    class: 'Storage optimized',
    vCPUs: '4',
    ram: '24 GiB',
    storage: '1 x 3750 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'is4gen.2xlarge',
    class: 'Storage optimized',
    vCPUs: '8',
    ram: '48 GiB',
    storage: '1 x 7500 SSD',
    io: 'Up to 25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'is4gen.4xlarge',
    class: 'Storage optimized',
    vCPUs: '16',
    ram: '96 GiB',
    storage: '2 x 7500 SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'is4gen.8xlarge',
    class: 'Storage optimized',
    vCPUs: '32',
    ram: '192 GiB',
    storage: '4 x 7500 SSD',
    io: '50 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm1.small',
    class: 'General purpose',
    vCPUs: '1',
    ram: '1.7 GiB',
    storage: '1 x 160 SSD',
    io: 'Low',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm1.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '3.75 GiB',
    storage: '1 x 410 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm1.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '7.5 GiB',
    storage: '2 x 420 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm1.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '15 GiB',
    storage: '4 x 420 SSD',
    io: 'High',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm2.xlarge',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '17.1 GiB',
    storage: '1 x 420 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm2.2xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '34.2 GiB',
    storage: '1 x 850 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm2.4xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '68.4 GiB',
    storage: '2 x 840 SSD',
    io: 'High',
    processor: 'Intel Xeon Family',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm3.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '3.75 GiB',
    storage: '1 x 4 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge/Sandy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm3.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '7.5 GiB',
    storage: '1 x 32 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge/Sandy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm3.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '15 GiB',
    storage: '2 x 40 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge/Sandy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm3.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '30 GiB',
    storage: '2 x 80 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge/Sandy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'm4.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm4.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm4.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm4.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm4.10xlarge',
    class: 'General purpose',
    vCPUs: '40',
    ram: '160 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2676 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm4.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm5.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5.metal',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5a.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '2 x 600 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5ad.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '2 x 600 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '20 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5d.metal',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '2 x 600 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 900 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5dn.metal',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5n.metal',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.3xlarge',
    class: 'General purpose',
    vCPUs: '12',
    ram: '48 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.6xlarge',
    class: 'General purpose',
    vCPUs: '24',
    ram: '96 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm5zn.metal',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.48xlarge',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6a.metal',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6g.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6g.metal',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '4 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6gd.metal',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6i.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6i.metal',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm6id.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 474 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 1425 NVMe SSD',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6id.metal',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 30000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 474 NVMe SSD',
    io: 'Up to 40000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: '4 x 1425 NVMe SSD',
    io: '150000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6idn.metal',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 30000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 40000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '100000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '150000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm6in.metal',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7a.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.32xlarge',
    class: 'General purpose',
    vCPUs: '128',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.48xlarge',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7a.metal-48xl',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7g.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7g.metal',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.medium',
    class: 'General purpose',
    vCPUs: '1',
    ram: '4 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7gd.metal',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i-flex.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i-flex.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i-flex.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i-flex.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i-flex.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'm7i.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.4xlarge',
    class: 'General purpose',
    vCPUs: '16',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.8xlarge',
    class: 'General purpose',
    vCPUs: '32',
    ram: '128 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.12xlarge',
    class: 'General purpose',
    vCPUs: '48',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.16xlarge',
    class: 'General purpose',
    vCPUs: '64',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.24xlarge',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.metal-24xl',
    class: 'General purpose',
    vCPUs: '96',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.48xlarge',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'm7i.metal-48xl',
    class: 'General purpose',
    vCPUs: '192',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'p2.xlarge',
    class: 'GPU instance',
    vCPUs: '4',
    ram: '61 GiB',
    storage: 'EBS only',
    io: 'High',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '12 GiB',
    allowList: true,
  },
  {
    type: 'p2.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '488 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '96 GiB',
    allowList: true,
  },
  {
    type: 'p2.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '732 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '16',
    gpuMemory: '192 GiB',
    allowList: true,
  },
  {
    type: 'p3.2xlarge',
    class: 'GPU instance',
    vCPUs: '8',
    ram: '61 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '1',
    gpuMemory: '16 GiB',
    allowList: true,
  },
  {
    type: 'p3.8xlarge',
    class: 'GPU instance',
    vCPUs: '32',
    ram: '244 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '4',
    gpuMemory: '64 GiB',
    allowList: true,
  },
  {
    type: 'p3.16xlarge',
    class: 'GPU instance',
    vCPUs: '64',
    ram: '488 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '128 GiB',
    allowList: true,
  },
  {
    type: 'p3dn.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8175 (Skylake)',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '256 GiB',
    allowList: true,
  },
  {
    type: 'p4d.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '1152 GiB',
    storage: '8 x 1000 SSD',
    io: '400 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '320 GiB',
    allowList: true,
  },
  {
    type: 'p4de.24xlarge',
    class: 'GPU instance',
    vCPUs: '96',
    ram: '1152 GiB',
    storage: '8 x 1000 SSD',
    io: '400 Gigabit',
    processor: 'Intel Xeon Platinum 8275L',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '640 GiB',
    allowList: true,
  },
  {
    type: 'p5.48xlarge',
    class: 'GPU instance',
    vCPUs: '192',
    ram: '2048 GiB',
    storage: '8 x 3840 GB SSD',
    io: '3200 Gigabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    gpu: '8',
    gpuMemory: '80 GB HBM3',
    allowList: true,
  },
  {
    type: 'r3.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '15.25 GiB',
    storage: '1 x 32 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'r3.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: '1 x 80 SSD',
    io: 'Moderate',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'r3.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '61 GiB',
    storage: '1 x 160 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'r3.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '122 GiB',
    storage: '1 x 320 SSD',
    io: 'High',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'r3.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '244 GiB',
    storage: '2 x 320 SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2670 v2 (Ivy Bridge)',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 'r4.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '15.25 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r4.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '30.5 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r4.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '61 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r4.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '122 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r4.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '244 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r4.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '488 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon E5-2686 v4 (Broadwell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r5.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5.metal',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5a.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 600 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5ad.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5b.metal',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 600 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '20 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5d.metal',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8175',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '2 x 300 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '2 x 600 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 900 GB NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '4 x 600 NVMe SSD',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5dn.metal',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r5n.metal',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8259 (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.48xlarge',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6a.metal',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 7R13 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6g.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6g.metal',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6gd.metal',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6i.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6i.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r6id.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 474 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '12500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '18750 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 1425 NVMe SSD',
    io: '37500 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6id.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 30000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 474 NVMe SSD',
    io: 'Up to 40000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: '4 x 1425 NVMe SSD',
    io: '150000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6idn.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 25000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 30000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 40000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '75000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '100000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '150000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r6in.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '200000 Megabit',
    processor: 'Intel Xeon 8375C (Ice Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7a.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.48xlarge',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7a.metal-48xl',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AMD EPYC 9R14 Processor',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7g.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7g.metal',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: '1 x 59 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: '1 x 950 NVMe SSD',
    io: 'Up to 15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '15 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '22500 Megabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7gd.metal',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '30 Gigabit',
    processor: 'AWS Graviton3 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7i.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '18750 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.metal-24xl',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '37500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.48xlarge',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7i.metal-48xl',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: true,
  },
  {
    type: 'r7iz.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '12500 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.metal-16xl',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r7iz.metal-32xl',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 12500 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: 'EBS only',
    io: 'Up to 15000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '128 GiB',
    storage: 'EBS only',
    io: 'Up to 15000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '256 GiB',
    storage: 'EBS only',
    io: '15000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: 'EBS only',
    io: '22500 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '512 GiB',
    storage: 'EBS only',
    io: '30000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '40000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.metal-24xl',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '40000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.48xlarge',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'r8g.metal-48xl',
    class: 'Memory optimized',
    vCPUs: '192',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '50000 Megabit',
    processor: 'AWS Graviton4 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't1.micro',
    class: 'Micro instances',
    vCPUs: '1',
    ram: '0.613 GiB',
    storage: 'EBS only',
    io: 'Very Low',
    processor: 'Variable',
    currentGeneration: 'No',
    allowList: false,
  },
  {
    type: 't2.nano',
    class: 'General purpose',
    vCPUs: '1',
    ram: '0.5 GiB',
    storage: 'EBS only',
    io: 'Low',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.micro',
    class: 'General purpose',
    vCPUs: '1',
    ram: '1 GiB',
    storage: 'EBS only',
    io: 'Low to Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.small',
    class: 'General purpose',
    vCPUs: '1',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Low to Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.medium',
    class: 'General purpose',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Low to Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Low to Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't2.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Moderate',
    processor: 'Intel Xeon Family',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.nano',
    class: 'General purpose',
    vCPUs: '2',
    ram: '0.5 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.micro',
    class: 'General purpose',
    vCPUs: '2',
    ram: '1 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.small',
    class: 'General purpose',
    vCPUs: '2',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.medium',
    class: 'General purpose',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'Intel Skylake E5 2686 v5',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.nano',
    class: 'General purpose',
    vCPUs: '2',
    ram: '0.5 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.micro',
    class: 'General purpose',
    vCPUs: '2',
    ram: '1 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.small',
    class: 'General purpose',
    vCPUs: '2',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.medium',
    class: 'General purpose',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't3a.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AMD EPYC 7571',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.nano',
    class: 'General purpose',
    vCPUs: '2',
    ram: '0.5 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.micro',
    class: 'General purpose',
    vCPUs: '2',
    ram: '1 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.small',
    class: 'General purpose',
    vCPUs: '2',
    ram: '2 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.medium',
    class: 'General purpose',
    vCPUs: '2',
    ram: '4 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.large',
    class: 'General purpose',
    vCPUs: '2',
    ram: '8 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.xlarge',
    class: 'General purpose',
    vCPUs: '4',
    ram: '16 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 't4g.2xlarge',
    class: 'General purpose',
    vCPUs: '8',
    ram: '32 GiB',
    storage: 'EBS only',
    io: 'Up to 5 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'trn1.2xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '8',
    ram: '32 GiB',
    storage: '1 x 475 NVMe SSD',
    io: '12500 Megabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    gpuMemory: '32 GB',
    allowList: false,
  },
  {
    type: 'trn1.32xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '800 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    gpuMemory: '512 GB',
    allowList: false,
  },
  {
    type: 'trn1n.32xlarge',
    class: 'Machine Learning ASIC Instances',
    vCPUs: '128',
    ram: '512 GiB',
    storage: '4 x 1900 NVMe SSD',
    io: '1600 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    gpuMemory: '512 GB',
    allowList: false,
  },
  {
    type: 'u-12tb1.112xlarge',
    class: 'Memory optimized',
    vCPUs: '448',
    ram: '12288 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Skylake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-18tb1.112xlarge',
    class: 'Memory optimized',
    vCPUs: '448',
    ram: '18432 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8280L (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-24tb1.112xlarge',
    class: 'Memory optimized',
    vCPUs: '448',
    ram: '24576 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8280L (Cascade Lake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-3tb1.56xlarge',
    class: 'Memory optimized',
    vCPUs: '224',
    ram: '3072 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Scalable (Skylake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-6tb1.112xlarge',
    class: 'Memory optimized',
    vCPUs: '448',
    ram: '6144 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Skylake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-6tb1.56xlarge',
    class: 'Memory optimized',
    vCPUs: '224',
    ram: '6144 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Skylake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u-9tb1.112xlarge',
    class: 'Memory optimized',
    vCPUs: '448',
    ram: '9216 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Skylake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u7i-12tb.224xlarge',
    class: 'Memory optimized',
    vCPUs: '896',
    ram: '12288 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u7in-16tb.224xlarge',
    class: 'Memory optimized',
    vCPUs: '896',
    ram: '16384 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u7in-24tb.224xlarge',
    class: 'Memory optimized',
    vCPUs: '896',
    ram: '24576 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'u7in-32tb.224xlarge',
    class: 'Memory optimized',
    vCPUs: '896',
    ram: '32768 GiB',
    storage: 'EBS only',
    io: '200 Gigabit',
    processor: 'Intel Xeon Scalable (Sapphire Rapids)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'vt1.3xlarge',
    class: 'Media Accelerator Instances',
    vCPUs: '12',
    ram: '24 GiB',
    storage: 'EBS only',
    io: '3125 Megabit',
    processor: 'Intel Xeon Platinum 8259CL',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'vt1.6xlarge',
    class: 'Media Accelerator Instances',
    vCPUs: '24',
    ram: '48 GiB',
    storage: 'EBS only',
    io: '6250 Megabit',
    processor: 'Intel Xeon Platinum 8259CL',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'vt1.24xlarge',
    class: 'Media Accelerator Instances',
    vCPUs: '96',
    ram: '192 GiB',
    storage: 'EBS only',
    io: '25000 Megabit',
    processor: 'Intel Xeon Platinum 8259CL',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '976 GiB',
    storage: '1 x 1920 SSD',
    io: 'High',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '1952 GiB',
    storage: '2 x 1920 SSD',
    io: 'High',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '122 GiB',
    storage: '1 x 120 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '244 GiB',
    storage: '1 x 240 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '488 GiB',
    storage: '1 x 480 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '976 GiB',
    storage: '1 x 960 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '1952 GiB',
    storage: '1 x 1920 SSD',
    io: '10 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x1e.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '3904 GiB',
    storage: '2 x 1920 SSD',
    io: '25 Gigabit',
    processor: 'High Frequency Intel Xeon E7-8880 v3 (Haswell)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.medium',
    class: 'Memory optimized',
    vCPUs: '1',
    ram: '16 GiB',
    storage: '1 x 59 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '32 GiB',
    storage: '1 x 118 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '64 GiB',
    storage: '1 x 237 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '128 GiB',
    storage: '1 x 474 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '256 GiB',
    storage: '1 x 950 SSD',
    io: 'Up to 10 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '512 GiB',
    storage: '1 x 1900 SSD',
    io: '12 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '768 GiB',
    storage: '2 x 1425 SSD',
    io: '20 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '1024 GiB',
    storage: '2 x 1900 SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2gd.metal',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '1024 GiB',
    storage: '2 x 1900 SSD',
    io: '25 Gigabit',
    processor: 'AWS Graviton2 Processor',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2idn.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '1024 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2idn.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '1536 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '75 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2idn.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '2048 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2idn.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '2048 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '128 GiB',
    storage: '1 x 118 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '256 GiB',
    storage: '1 x 237 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '512 GiB',
    storage: '1 x 475 NVMe SSD',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '1024 GiB',
    storage: '1 x 950 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.16xlarge',
    class: 'Memory optimized',
    vCPUs: '64',
    ram: '2048 GiB',
    storage: '1 x 1900 NVMe SSD',
    io: '50 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.24xlarge',
    class: 'Memory optimized',
    vCPUs: '96',
    ram: '3072 GiB',
    storage: '2 x 1425 NVMe SSD',
    io: '75 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.32xlarge',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '4096 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iedn.metal',
    class: 'Memory optimized',
    vCPUs: '128',
    ram: '4096 GiB',
    storage: '2 x 1900 NVMe SSD',
    io: '100 Gigabit',
    processor: 'Intel Xeon Scalable (Icelake)',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '256 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.4xlarge',
    class: 'Memory optimized',
    vCPUs: '16',
    ram: '512 GiB',
    storage: 'EBS only',
    io: 'Up to 25 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.6xlarge',
    class: 'Memory optimized',
    vCPUs: '24',
    ram: '768 GiB',
    storage: 'EBS only',
    io: '50 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.8xlarge',
    class: 'Memory optimized',
    vCPUs: '32',
    ram: '1024 GiB',
    storage: 'EBS only',
    io: '75 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'x2iezn.metal',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '1536 GiB',
    storage: 'EBS only',
    io: '100 Gigabit',
    processor: 'Intel Xeon Platinum 8252',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.large',
    class: 'Memory optimized',
    vCPUs: '2',
    ram: '16 GiB',
    storage: '1 x 75 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.xlarge',
    class: 'Memory optimized',
    vCPUs: '4',
    ram: '32 GiB',
    storage: '1 x 150 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.2xlarge',
    class: 'Memory optimized',
    vCPUs: '8',
    ram: '64 GiB',
    storage: '1 x 300 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.3xlarge',
    class: 'Memory optimized',
    vCPUs: '12',
    ram: '96 GiB',
    storage: '1 x 450 NVMe SSD',
    io: 'Up to 10 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.6xlarge',
    class: 'Memory optimized',
    vCPUs: '24',
    ram: '192 GiB',
    storage: '1 x 900 NVMe SSD',
    io: '10 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.12xlarge',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
  {
    type: 'z1d.metal',
    class: 'Memory optimized',
    vCPUs: '48',
    ram: '384 GiB',
    storage: '2 x 900 NVMe SSD',
    io: '25 Gigabit',
    processor: 'Intel Xeon Platinum 8151',
    currentGeneration: 'Yes',
    allowList: false,
  },
];

export const ALL_EC2_INSTANCE_TYPES = Ec2Details;
export const FILTERED_EC2_INSTANCE_TYPES =
  ALL_EC2_INSTANCE_TYPES.filter(filterArmAmis).filter(onlyShowAllowList);
