import { AwsPrices } from './aws-prices-details';
const RedshiftPrices: AwsPrices = {
  lastUpdated: 1724251042406,
  publishedDate: '2024-08-07T22:16:11Z',
  prices: {
    'ap-south-1': {
      'dc1.8xlarge': 6.1,
      'dc1.large': 0.315,
      'dc2.8xlarge': 6.1,
      'dc2.large': 0.315,
      'ds2.8xlarge': 9.5,
      'ds2.xlarge': 1.19,
      'ra3.16xlarge': 14.827,
      'ra3.4xlarge': 3.706,
      'ra3.xlplus': 1.235,
    },
    'ap-southeast-1': {
      'dc1.8xlarge': 6.4,
      'dc1.large': 0.33,
      'dc2.8xlarge': 6.4,
      'dc2.large': 0.33,
      'ds1.8xlarge': 10,
      'ds1.xlarge': 1.25,
      'ds2.8xlarge': 10,
      'ds2.xlarge': 1.25,
      'ra3.16xlarge': 15.636,
      'ra3.4xlarge': 3.909,
      'ra3.xlplus': 1.303,
    },
    'eu-west-1': {
      'dc1.8xlarge': 5.6,
      'dc1.large': 0.3,
      'dc2.8xlarge': 5.6,
      'dc2.large': 0.3,
      'ds1.8xlarge': 7.6,
      'ds1.xlarge': 0.95,
      'ds2.8xlarge': 7.6,
      'ds2.xlarge': 0.95,
      'ra3.16xlarge': 14.424,
      'ra3.4xlarge': 3.606,
      'ra3.xlplus': 1.202,
    },
    'us-east-1': {
      'dc1.8xlarge': 4.8,
      'dc1.large': 0.25,
      'dc2.8xlarge': 4.8,
      'dc2.large': 0.25,
      'ds1.8xlarge': 6.8,
      'ds1.xlarge': 0.85,
      'ds2.8xlarge': 6.8,
      'ds2.xlarge': 0.85,
      'ra3.16xlarge': 13.04,
      'ra3.4xlarge': 3.26,
      'ra3.large': 0.543,
      'ra3.xlplus': 1.086,
    },
    'us-east-2': {
      'dc1.8xlarge': 4.8,
      'dc1.large': 0.25,
      'dc2.8xlarge': 4.8,
      'dc2.large': 0.25,
      'ds2.8xlarge': 6.8,
      'ds2.xlarge': 0.85,
      'ra3.16xlarge': 13.04,
      'ra3.4xlarge': 3.26,
      'ra3.xlplus': 1.086,
    },
    'us-west-2': {
      'dc1.8xlarge': 4.8,
      'dc1.large': 0.25,
      'dc2.8xlarge': 4.8,
      'dc2.large': 0.25,
      'ds1.8xlarge': 6.8,
      'ds1.xlarge': 0.85,
      'ds2.8xlarge': 6.8,
      'ds2.xlarge': 0.85,
      'ra3.16xlarge': 13.04,
      'ra3.4xlarge': 3.26,
      'ra3.xlplus': 1.086,
    },
  },
};
export default RedshiftPrices;
