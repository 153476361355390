import { AwsPrices } from './aws-prices-details';
const EbsPrices: AwsPrices = {
  lastUpdated: 1724251224173,
  publishedDate: '2024-08-15T19:52:27Z',
  prices: {
    'ap-south-1': {
      gp2: 0.114,
      gp3: 0.0912,
      'gp3-iops': 0.0057,
      'gp3-throughput': 46.6944,
      io1: 0.131,
      'io1-iops': 0.068,
      io2: 0.131,
      'io2-iops': 0.068,
      'io2-iops.tier2': 0.0476,
      'io2-iops.tier3': 0.03332,
      sc1: 0.0174,
      st1: 0.051,
      standard: 0.08,
      'standard-iops': 8e-8,
    },
    'ap-southeast-1': {
      gp2: 0.12,
      gp3: 0.096,
      'gp3-iops': 0.006,
      'gp3-throughput': 49.152,
      io1: 0.138,
      'io1-iops': 0.072,
      io2: 0.138,
      'io2-iops': 0.072,
      'io2-iops.tier2': 0.0504,
      'io2-iops.tier3': 0.03528,
      sc1: 0.018,
      st1: 0.054,
      standard: 0.08,
      'standard-iops': 8e-8,
    },
    'eu-west-1': {
      gp2: 0.11,
      gp3: 0.088,
      'gp3-iops': 0.0055,
      'gp3-throughput': 45.056,
      io1: 0.138,
      'io1-iops': 0.072,
      io2: 0.138,
      'io2-iops': 0.072,
      'io2-iops.tier2': 0.0504,
      'io2-iops.tier3': 0.03528,
      sc1: 0.0168,
      st1: 0.05,
      standard: 0.055,
      'standard-iops': 5.5e-8,
    },
    'us-east-1': {
      gp2: 0.1,
      gp3: 0.08,
      'gp3-iops': 0.005,
      'gp3-throughput': 40.96,
      io1: 0.125,
      'io1-iops': 0.065,
      io2: 0.125,
      'io2-iops': 0.065,
      'io2-iops.tier2': 0.0455,
      'io2-iops.tier3': 0.03185,
      sc1: 0.015,
      st1: 0.045,
      standard: 0.05,
      'standard-iops': 5e-8,
    },
    'us-east-2': {
      gp2: 0.1,
      gp3: 0.08,
      'gp3-iops': 0.005,
      'gp3-throughput': 40.96,
      io1: 0.125,
      'io1-iops': 0.065,
      io2: 0.125,
      'io2-iops': 0.065,
      'io2-iops.tier2': 0.0455,
      'io2-iops.tier3': 0.03185,
      sc1: 0.015,
      st1: 0.045,
      standard: 0.05,
      'standard-iops': 5e-8,
    },
    'us-west-2': {
      gp2: 0.1,
      gp3: 0.08,
      'gp3-iops': 0.005,
      'gp3-throughput': 40.96,
      io1: 0.125,
      'io1-iops': 0.065,
      io2: 0.125,
      'io2-iops': 0.065,
      'io2-iops.tier2': 0.0455,
      'io2-iops.tier3': 0.03185,
      sc1: 0.015,
      st1: 0.045,
      standard: 0.05,
      'standard-iops': 5e-8,
    },
  },
};
export default EbsPrices;
